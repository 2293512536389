define("ember-ref-modifier/modifiers/ref", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function hasValidTarget(target) {
    return typeof target === 'object' && target !== null && !Array.isArray(target);
  }

  function hasValidProperty(prop) {
    return typeof prop === 'string';
  }

  function getParams([maybeTarget, maybePropName]) {
    const isPropNameString = typeof maybePropName === 'string';

    if (!isPropNameString) {
      (true && !(false) && Ember.deprecate('ember-ref-modifier: {{ref "propertyName" context}} has been changed to {{ref context "propertyName"}}. Please migrate to use this.', false, {
        id: '@ember-ref-modifier--arguments-ordering-deprecation',
        until: 'v1.0.0'
      }));
    }

    return {
      propName: isPropNameString ? maybePropName : maybeTarget,
      target: isPropNameString ? maybeTarget : maybePropName
    };
  }

  var _default = Ember._setModifierManager(() => ({
    capabilities: Ember._modifierManagerCapabilities ? Ember._modifierManagerCapabilities('3.13') : undefined,

    createModifier() {
      return {
        element: undefined,
        propName: undefined,
        target: undefined
      };
    },

    installModifier(state, element, {
      positional
    }) {
      const {
        propName,
        target
      } = getParams(positional);

      if (hasValidProperty(propName) && hasValidTarget(target)) {
        Ember.set(target, propName, element);
        state.propName = propName;
        state.target = target;
      }

      state.element = element;
    },

    updateModifier(state, {
      positional
    }) {
      const {
        propName,
        target
      } = getParams(positional);

      if (hasValidProperty(propName) && hasValidTarget(target)) {
        if (hasValidProperty(state.propName) && hasValidTarget(state.target)) {
          if (Ember.get(target, propName) !== Ember.get(state.target, state.propName)) {
            Ember.set(state.target, state.propName, null);
          }
        }

        Ember.set(target, propName, state.element);
        state.propName = propName;
        state.target = target;
      }
    },

    destroyModifier({
      target,
      propName
    }) {
      if (hasValidProperty(propName) && hasValidTarget(target)) {
        Ember.set(target, propName, null);
      }
    }

  }), class RefModifier {});

  _exports.default = _default;
});