define("ember-bootstrap/components/bs-popover/element", ["exports", "ember-bootstrap/components/base/bs-popover/element"], function (_exports, _element) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _element.default.extend({
    popperClassNames: Ember.computed('fade', 'actualPlacement', 'showHelp', function () {
      let classes = ['popover', "bs-popover-".concat(this.get('actualPlacement'))];

      if (this.get('fade')) {
        classes.push('fade');
      }

      if (this.get('showHelp')) {
        classes.push('show');
      }

      return classes;
    }),

    /**
     * @property titleClass
     * @private
     */
    titleClass: 'popover-header',

    /**
     * @property contentClass
     * @private
     */
    contentClass: 'popover-body'
  });

  _exports.default = _default;
});