define("ember-bootstrap/components/base/bs-modal/header", ["exports", "ember-bootstrap/templates/components/bs-modal/header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   Modal header element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalHeader
   @namespace Components
   @extends Ember.Component
   @public
   */
  var _default = Ember.Component.extend({
    layout: _header.default,
    classNames: ['modal-header'],

    /**
     * Show a close button (x icon)
     *
     * @property closeButton
     * @type boolean
     * @default true
     * @public
     */
    closeButton: true,

    /**
     * The title to display in the modal header
     *
     * @property title
     * @type string
     * @default null
     * @public
     */

    /**
     * @property titleComponent
     * @type {String}
     * @private
     */
    titleComponent: 'bs-modal/header/title',

    /**
     * @property closeComponent
     * @type {String}
     * @private
     */
    closeComponent: 'bs-modal/header/close'
    /**
     * @event onClose
     * @public
     */

  });

  _exports.default = _default;
});