define("ember-bootstrap/components/bs-modal/header/title", ["exports", "ember-bootstrap/components/base/bs-modal/header/title"], function (_exports, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _title.default.extend({
    tagName: 'h5'
  });

  _exports.default = _default;
});