define("ember-bootstrap/components/bs-nav", ["exports", "ember-bootstrap/components/base/bs-nav"], function (_exports, _bsNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsNav.default.extend({
    classNameBindings: ['stacked:flex-column', 'fill:nav-fill'],

    /**
      * Make the nav flex fill, see [bootstrap docs](http://getbootstrap.com/docs/4.1/components/navs/#fill-and-justify)
      *
      * @property fill
      * @type boolean
      * @default false
      * @public
      */
    fill: false
  });

  _exports.default = _default;
});