define("ember-bootstrap/components/bs-form/element/control/checkbox", ["exports", "ember-bootstrap/components/base/bs-form/element/control/checkbox", "ember-bootstrap/utils/cp/form-validation-class"], function (_exports, _checkbox, _formValidationClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _checkbox.default.extend({
    classNames: ['form-check-input'],
    classNameBindings: ['formValidationClass'],
    formValidationClass: (0, _formValidationClass.default)('validationType')
  });

  _exports.default = _default;
});