define("ember-bootstrap/components/bs-form/group", ["exports", "ember-bootstrap/components/base/bs-form/group"], function (_exports, _group) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _group.default.extend({
    classNames: ['form-group'],
    classNameBindings: ['isHorizontal:row'],

    /**
     * Indicates whether the form type equals `horizontal`
     *
     * @property isHorizontal
     * @type boolean
     * @private
     */
    isHorizontal: Ember.computed.equal('formLayout', 'horizontal').readOnly()
  });

  _exports.default = _default;
});