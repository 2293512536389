define("ember-bootstrap/components/bs-dropdown/menu/item", ["exports", "ember-bootstrap/components/base/bs-dropdown/menu/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _item.default.extend({
    tagName: ''
  });

  _exports.default = _default;
});