define("ember-bootstrap/components/base/bs-form/element/feedback-icon", ["exports", "ember-bootstrap/templates/components/bs-form/element/feedback-icon"], function (_exports, _feedbackIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   @class FormElementFeedbackIcon
   @namespace Components
   @extends Ember.Component
   @private
   */
  var _default = Ember.Component.extend({
    layout: _feedbackIcon.default,
    tagName: '',

    /**
     * @property show
     * @type {Boolean}
     * @public
     */
    show: false,

    /**
     * @property iconName
     * @type {String}
     * @public
     */
    iconName: null
  });

  _exports.default = _default;
});