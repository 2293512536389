define("ember-bootstrap/components/bs-form/element/errors", ["exports", "ember-bootstrap/components/base/bs-form/element/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _errors.default.extend({
    feedbackClass: 'invalid-feedback d-block'
  });

  _exports.default = _default;
});