define("ember-bootstrap/components/bs-tab/pane", ["exports", "ember-bootstrap/components/base/bs-tab/pane"], function (_exports, _pane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pane.default.extend({
    classNameBindings: ['showContent:show']
  });

  _exports.default = _default;
});