define("ember-bootstrap/components/base/bs-dropdown/menu/link-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   Extended `{{link-to}}` component for use within Dropdowns.
  
   @class DropdownMenuLinkTo
   @namespace Components
   @extends Ember.LinkComponent
   @public
   */
  var _default = Ember.LinkComponent.extend({});

  _exports.default = _default;
});