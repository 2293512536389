define("ember-bootstrap/components/base/bs-navbar/content", ["exports", "ember-bootstrap/templates/components/bs-navbar/content", "ember-bootstrap/components/bs-collapse"], function (_exports, _content, _bsCollapse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component to wrap the collapsible content of a [Components.Navbar](Components.Navbar.html) component.
   * Have a look there for examples.
   *
   * @class NavbarContent
   * @namespace Components
   * @extends Components.Collapse
   * @public
   */
  var _default = _bsCollapse.default.extend({
    layout: _content.default,
    classNames: ['navbar-collapse']
  });

  _exports.default = _default;
});