define("ember-bootstrap/components/bs-progress/bar", ["exports", "ember-bootstrap/components/base/bs-progress/bar", "ember-bootstrap/utils/cp/type-class"], function (_exports, _bar, _typeClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bar.default.extend({
    classNameBindings: ['progressBarAnimate:progress-bar-animated'],
    typeClass: (0, _typeClass.default)('bg', 'type')
  });

  _exports.default = _default;
});