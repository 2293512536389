define("ember-bootstrap/components/bs-dropdown", ["exports", "ember-bootstrap/components/base/bs-dropdown"], function (_exports, _bsDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsDropdown.default.extend({
    classNameBindings: ['inNav:nav-item', 'isOpen:show']
  });

  _exports.default = _default;
});