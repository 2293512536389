define("ember-bootstrap/components/base/bs-form/element/control/checkbox", ["exports", "ember-bootstrap/components/base/bs-form/element/control", "ember-bootstrap/components/base/bs-form/element/control/input"], function (_exports, _control, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   @class FormElementControlCheckbox
   @namespace Components
   @extends Components.FormElementControl
   @private
   */
  var _default = _control.default.extend({
    tagName: 'input',
    attributeBindings: [..._input.baseAttributes, 'value:checked', 'type'],

    /**
     * @property type
     * @type {String}
     * @readonly
     * @private
     */
    type: 'checkbox',

    click(event) {
      this.get('onChange')(event.target.checked);
    }

  });

  _exports.default = _default;
});