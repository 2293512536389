define("ember-bootstrap/components/bs-accordion", ["exports", "ember-bootstrap/components/base/bs-accordion"], function (_exports, _bsAccordion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsAccordion.default.extend({
    classNames: ['accordion']
  });

  _exports.default = _default;
});