define("ember-bootstrap/components/base/bs-modal/header/title", ["exports", "ember-bootstrap/templates/components/bs-modal/header/title"], function (_exports, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   @class ModalHeaderTitle
   @namespace Components
   @extends Ember.Component
   @private
   */
  var _default = Ember.Component.extend({
    layout: _title.default,
    tagName: 'h4',
    classNames: ['modal-title']
  });

  _exports.default = _default;
});