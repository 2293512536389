define("ember-bootstrap/components/base/bs-popover/element", ["exports", "ember-bootstrap/components/base/bs-contextual-help/element", "ember-bootstrap/templates/components/bs-popover/element"], function (_exports, _element, _element2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   Internal component for popover's markup. Should not be used directly.
  
   @class PopoverElement
   @namespace Components
   @extends Components.ContextualHelpElement
   @private
   */
  var _default = _element.default.extend({
    layout: _element2.default,

    /**
     * @property title
     * @type string
     * @public
     */

    /**
     * @property hasTitle
     * @type boolean
     * @private
     */
    hasTitle: Ember.computed.notEmpty('title')
  });

  _exports.default = _default;
});