define("ember-bootstrap/components/bs-form/element/layout/horizontal/checkbox", ["exports", "ember-bootstrap/components/bs-form/element/layout/horizontal", "ember-bootstrap/templates/components/bs-form/element/layout/horizontal/checkbox"], function (_exports, _horizontal, _checkbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   @class FormElementLayoutHorizontalCheckbox
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  var _default = _horizontal.default.extend({
    layout: _checkbox.default
  });

  _exports.default = _default;
});