define("ember-bootstrap/components/base/bs-modal/body", ["exports", "ember-bootstrap/templates/components/bs-modal/body"], function (_exports, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   Modal body element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalBody
   @namespace Components
   @extends Ember.Component
   @public
   */
  var _default = Ember.Component.extend({
    layout: _body.default,
    classNames: ['modal-body']
  });

  _exports.default = _default;
});