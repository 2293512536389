define("ember-bootstrap/components/bs-carousel/slide", ["exports", "ember-bootstrap/components/base/bs-carousel/slide"], function (_exports, _slide) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _slide.default.extend({
    classNameBindings: ['left:carousel-item-left', 'next:carousel-item-next', 'prev:carousel-item-prev', 'right:carousel-item-right'],
    classNames: ['carousel-item']
  });

  _exports.default = _default;
});