define("ember-bootstrap/components/bs-form/element/control/textarea", ["exports", "ember-bootstrap/components/base/bs-form/element/control/textarea", "ember-bootstrap/utils/cp/form-validation-class"], function (_exports, _textarea, _formValidationClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _textarea.default.extend({
    classNameBindings: ['formValidationClass'],
    formValidationClass: (0, _formValidationClass.default)('validationType')
  });

  _exports.default = _default;
});