define("ember-bootstrap/components/base/bs-nav/item", ["exports", "ember-bootstrap/templates/components/bs-nav/item", "ember-bootstrap/mixins/component-parent", "ember-bootstrap/utils/cp/overrideable"], function (_exports, _item, _componentParent, _overrideable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   Component for each item within a [Components.Nav](Components.Nav.html) component. Have a look there for examples.
  
   @class NavItem
   @namespace Components
   @extends Ember.Component
   @uses Mixins.ComponentParent
   @public
   */
  var _default = Ember.Component.extend(_componentParent.default, {
    layout: _item.default,
    classNameBindings: ['disabled', 'active'],
    tagName: 'li',

    /**
     * Render the nav item as disabled (see [Bootstrap docs](http://getbootstrap.com/components/#nav-disabled-links)).
     * By default it will look at any nested `link-to` components and make itself disabled if there is a disabled link.
     * See the [link-to API](http://emberjs.com/api/classes/Ember.Templates.helpers.html#toc_disabling-the-code-link-to-code-component)
     *
     * @property disabled
     * @type boolean
     * @public
     */
    disabled: (0, _overrideable.default)('_disabled', function () {
      return this.get('_disabled');
    }),
    _disabled: false,

    /**
     * Render the nav item as active.
     * By default it will look at any nested `link-to` components and make itself active if there is an active link
     * (i.e. the link points to the current route).
     * See the [link-to API](http://emberjs.com/api/classes/Ember.Templates.helpers.html#toc_handling-current-route)
     *
     * @property active
     * @type boolean
     * @public
     */
    active: (0, _overrideable.default)('_active', function () {
      return this.get('_active');
    }),
    _active: false,

    /**
     * Collection of all `Ember.LinkComponent`s that are children
     *
     * @property childLinks
     * @private
     */
    childLinks: Ember.computed.filter('children', function (view) {
      return view instanceof Ember.LinkComponent;
    }),
    activeChildLinks: Ember.computed.filterBy('childLinks', 'active'),
    hasActiveChildLinks: Ember.computed.gt('activeChildLinks.length', 0),
    disabledChildLinks: Ember.computed.filterBy('childLinks', 'disabled'),
    hasDisabledChildLinks: Ember.computed.gt('disabledChildLinks.length', 0),

    /**
     * Called when clicking the nav item
     *
     * @event onClick
     * @public
     */
    onClick() {},

    click() {
      this.onClick();
    },

    init() {
      this._super(...arguments);

      let {
        model,
        models
      } = this.getProperties('model', 'models');
      (true && !(!model || !models) && Ember.assert('You cannot pass both `@model` and `@models` to a nav item component!', !model || !models));
      this.get('activeChildLinks');
      this.get('disabledChildLinks');
    },

    _observeActive: Ember.observer('activeChildLinks.[]', function () {
      Ember.run.scheduleOnce('afterRender', this, this._updateActive);
    }),

    _updateActive() {
      this.set('_active', this.get('hasActiveChildLinks'));
    },

    _observeDisabled: Ember.observer('disabledChildLinks.[]', function () {
      Ember.run.scheduleOnce('afterRender', this, this._updateDisabled);
    }),

    _updateDisabled() {
      this.set('_disabled', this.get('hasDisabledChildLinks'));
    }

  });

  _exports.default = _default;
});