define("ember-bootstrap/components/bs-accordion/item", ["exports", "ember-bootstrap/components/base/bs-accordion/item", "ember-bootstrap/utils/cp/type-class"], function (_exports, _item, _typeClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _item.default.extend({
    classNames: ['card'],
    typeClass: (0, _typeClass.default)('bg', 'type')
  });

  _exports.default = _default;
});