define("ember-bootstrap/components/bs-alert", ["exports", "ember-bootstrap/components/base/bs-alert"], function (_exports, _bsAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsAlert.default.extend({
    classNameBindings: ['showAlert:show']
  });

  _exports.default = _default;
});