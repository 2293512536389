define("ember-bootstrap/components/base/bs-tooltip/element", ["exports", "ember-bootstrap/templates/components/bs-tooltip/element", "ember-bootstrap/components/base/bs-contextual-help/element"], function (_exports, _element, _element2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   Internal component for tooltip's markup. Should not be used directly.
  
   @class TooltipElement
   @namespace Components
   @extends Components.ContextualHelpElement
   @private
   */
  var _default = _element2.default.extend({
    layout: _element.default
  });

  _exports.default = _default;
});