define("ember-bootstrap/components/base/bs-form/element/layout/vertical", ["exports", "ember-bootstrap/components/base/bs-form/element/layout", "ember-bootstrap/templates/components/bs-form/element/layout/vertical"], function (_exports, _layout, _vertical) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   @class FormElementLayoutVertical
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  var _default = _layout.default.extend({
    layout: _vertical.default
  });

  _exports.default = _default;
});