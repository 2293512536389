define("ember-bootstrap/components/base/bs-form/element/layout/vertical/checkbox", ["exports", "ember-bootstrap/components/base/bs-form/element/layout/vertical", "ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox"], function (_exports, _vertical, _checkbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   @class FormElementLayoutVerticalCheckbox
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  var _default = _vertical.default.extend({
    layout: _checkbox.default
  });

  _exports.default = _default;
});