define("ember-bootstrap/components/bs-carousel", ["exports", "ember-bootstrap/components/base/bs-carousel"], function (_exports, _bsCarousel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsCarousel.default.extend({
    nextControlClassName: 'carousel-control-next',
    nextControlIcon: 'carousel-control-next-icon',
    prevControlClassName: 'carousel-control-prev',
    prevControlIcon: 'carousel-control-prev-icon',
    classNameBindings: ['carouselFade'],
    carouselFade: Ember.computed.equal('transition', 'fade').readOnly()
  });

  _exports.default = _default;
});