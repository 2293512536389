define("ember-bootstrap/components/bs-dropdown/menu/divider", ["exports", "ember-bootstrap/components/base/bs-dropdown/menu/divider"], function (_exports, _divider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _divider.default.extend({
    classNames: ['dropdown-divider']
  });

  _exports.default = _default;
});