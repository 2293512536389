define("ember-bootstrap/components/base/bs-form/element/errors", ["exports", "ember-bootstrap/templates/components/bs-form/element/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   @class FormElementErrors
   @namespace Components
   @extends Ember.Component
   @private
   */
  var _default = Ember.Component.extend({
    layout: _errors.default,
    tagName: '',

    /**
     * @property show
     * @type {Boolean}
     * @public
     */
    show: false,

    /**
     * @property messages
     * @type {Ember.Array}
     * @public
     */

    /**
     * Whether or not should display several errors at the same time.
     *
     * @default false
     * @property showMultipleErrors
     * @public
     * @type {Boolean}
     */
    showMultipleErrors: false
  });

  _exports.default = _default;
});