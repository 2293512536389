define("ember-bootstrap/components/bs-form/element/help-text", ["exports", "ember-bootstrap/components/base/bs-form/element/help-text"], function (_exports, _helpText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helpText.default.extend({
    classNames: ['form-text']
  });

  _exports.default = _default;
});