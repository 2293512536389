define("ember-bootstrap/components/bs-form/element/control/input", ["exports", "ember-bootstrap/components/base/bs-form/element/control/input", "ember-bootstrap/utils/cp/form-validation-class", "ember-bootstrap/utils/cp/size-class"], function (_exports, _input, _formValidationClass, _sizeClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _input.default.extend({
    classNameBindings: ['formValidationClass', 'sizeClass'],
    formValidationClass: (0, _formValidationClass.default)('validationType'),

    /**
     * Property for size styling, set to 'lg', 'sm' or 'xs'
     *
     * Also see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/forms/#sizing)
     *
     * @property size
     * @type String
     * @public
     */
    size: null,
    sizeClass: (0, _sizeClass.default)('form-control', 'size')
  });

  _exports.default = _default;
});