define("ember-bootstrap/components/bs-button", ["exports", "ember-bootstrap/components/base/bs-button"], function (_exports, _bsButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsButton.default.extend({
    type: 'secondary',

    /**
     * Property to create outline buttons (BS4+ only)
     *
     * @property disabled
     * @type boolean
     * @default false
     * @public
     */
    outline: false
  });

  _exports.default = _default;
});