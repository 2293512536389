define("ember-bootstrap/components/base/bs-button-group/button", ["exports", "ember-bootstrap/components/bs-button"], function (_exports, _bsButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   Internal component for button-group buttons
  
   @class ButtonGroupButton
   @namespace Components
   @extends Components.Button
   @private
   */
  var _default = _bsButton.default.extend({
    /**
     * @property groupValue
     * @private
     */
    groupValue: null,

    /**
     * @property buttonGroupType
     * @type string
     * @private
     */
    buttonGroupType: false,

    /**
     * @property active
     * @type boolean
     * @readonly
     * @private
     */
    active: Ember.computed('buttonGroupType', 'groupValue.[]', 'value', function () {
      let {
        value,
        groupValue
      } = this.getProperties('value', 'groupValue');

      if (this.get('buttonGroupType') === 'radio') {
        return value === groupValue;
      } else {
        if (Ember.isArray(groupValue)) {
          return groupValue.indexOf(value) !== -1;
        }
      }

      return false;
    }).readOnly()
  });

  _exports.default = _default;
});