define("ember-bootstrap/components/base/bs-accordion/item/body", ["exports", "ember-bootstrap/templates/components/bs-accordion/body"], function (_exports, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   Component for an accordion item body.
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionBody
   @namespace Components
   @extends Ember.Component
   @public
   */
  var _default = Ember.Component.extend({
    layout: _body.default,
    tagName: '',

    /**
     * @property collapsed
     * @type boolean
     * @public
     */

    /**
     * @property collapseComponent
     * @type {String}
     * @private
     */
    collapseComponent: 'bs-collapse'
  });

  _exports.default = _default;
});