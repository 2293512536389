define("ember-bootstrap/components/bs-dropdown/menu/link-to", ["exports", "ember-bootstrap/components/base/bs-dropdown/menu/link-to"], function (_exports, _linkTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _linkTo.default.extend({
    classNames: ['dropdown-item']
  });

  _exports.default = _default;
});