define("ember-bootstrap/components/base/bs-navbar/toggle", ["exports", "ember-bootstrap/templates/components/bs-navbar/toggle"], function (_exports, _toggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component to implement the responsive menu toggle behavior in a [Components.Navbar](Components.Navbar.html)
   * component. Have a look there for examples.
   *
   * ### Bootstrap 3/4 Notes
   *
   * The inline version of the component uses the triple `icon-bar` styling for Bootstrap 3 and the
   * `navbar-toggler-icon` styling for Bootstrap 4.
   *
   * @class NavbarToggle
   * @namespace Components
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _toggle.default,
    tagName: 'button',
    classNameBindings: ['collapsed'],
    collapsed: true,

    /**
     * @event onClick
     * @public
     */
    onClick() {},

    click() {
      this.onClick();
    }

  });

  _exports.default = _default;
});