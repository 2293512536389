define("ember-bootstrap/components/bs-navbar/toggle", ["exports", "ember-bootstrap/components/base/bs-navbar/toggle"], function (_exports, _toggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toggle.default.extend({
    classNames: ['navbar-toggler']
  });

  _exports.default = _default;
});