define("ember-bootstrap/components/base/bs-form/element/help-text", ["exports", "ember-bootstrap/templates/components/bs-form/element/help-text"], function (_exports, _helpText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   @class FormElementHelpText
   @namespace Components
   @extends Ember.Component
   @private
   */
  var _default = Ember.Component.extend({
    layout: _helpText.default
    /**
     * @property text
     * @type {string}
     * @public
     */

  });

  _exports.default = _default;
});