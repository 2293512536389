define("ember-bootstrap/components/base/bs-form/element/control/radio", ["exports", "ember-bootstrap/components/base/bs-form/element/control", "ember-bootstrap/templates/components/bs-form/element/control/radio"], function (_exports, _control, _radio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   @class FormElementControlRadio
   @namespace Components
   @extends Components.FormElementControl
   @private
   */
  var _default = _control.default.extend({
    layout: _radio.default,
    tagName: '',

    /**
     * @property inline
     * @type {Boolean}
     * @default false
     * @public
     */
    inline: false
  });

  _exports.default = _default;
});