define("ember-bootstrap/components/base/bs-dropdown/menu", ["exports", "ember-bootstrap/templates/components/bs-dropdown/menu", "ember-bootstrap/utils/dom"], function (_exports, _menu, _dom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   Component for the dropdown menu.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownMenu
   @namespace Components
   @extends Ember.Component
   @public
   */
  var _default = Ember.Component.extend({
    layout: _menu.default,
    tagName: '',

    /**
     * @property ariaRole
     * @default menu
     * @type string
     * @protected
     */
    ariaRole: 'menu',

    /**
     * Alignment of the menu, either "left" or "right"
     *
     * @property align
     * @type string
     * @default left
     * @public
     */
    align: 'left',

    /**
     * @property direction
     * @default 'down'
     * @type string
     * @private
     */
    direction: 'down',

    /**
     * @property inNav
     * @type {boolean}
     * @private
     */
    inNav: false,

    /**
     * By default the menu is rendered in the same place the dropdown. If you experience clipping
     * issues, you can set this to false to render the menu in a wormhole at the top of the DOM.
     *
     * @property renderInPlace
     * @type boolean
     * @default true
     * @public
     */
    renderInPlace: true,

    /**
     * @property _renderInPlace
     * @type boolean
     * @private
     */
    _renderInPlace: Ember.computed('renderInPlace', function () {
      return this.get('renderInPlace') || !this.destinationElement;
    }),

    /**
     * The wormhole destinationElement
     *
     * @property destinationElement
     * @type object
     * @readonly
     * @private
     */
    destinationElement: Ember.computed(function () {
      return (0, _dom.getDestinationElement)(this);
    }),
    alignClass: Ember.computed('align', function () {
      if (this.get('align') !== 'left') {
        return "dropdown-menu-".concat(this.get('align'));
      }
    }),
    isOpen: Ember.computed({
      get() {
        return false;
      },

      set(key, value) {
        // delay removing the menu from DOM to allow (delegated Ember) event to fire for the menu's children
        // Fixes https://github.com/kaliber5/ember-bootstrap/issues/660
        Ember.run.next(() => {
          if (this.get('isDestroying') || this.get('isDestroyed')) {
            return;
          }

          this.set('_isOpen', value);
        });
        return value;
      }

    }),
    _isOpen: false,
    flip: true,
    _popperApi: null,
    popperPlacement: Ember.computed('direction', 'align', function () {
      let placement = 'bottom-start';
      let {
        direction,
        align
      } = this.getProperties('direction', 'align');

      if (direction === 'up') {
        placement = 'top-start';

        if (align === 'right') {
          placement = 'top-end';
        }
      } else if (direction === 'left') {
        placement = 'left-start';
      } else if (direction === 'right') {
        placement = 'right-start';
      } else if (align === 'right') {
        placement = 'bottom-end';
      }

      return placement;
    }),
    popperModifiers: Ember.computed('inNav', 'flip', function () {
      return {
        // @todo add offset config
        applyStyle: {
          enabled: !this.get('inNav')
        },
        flip: {
          enabled: this.get('flip')
        }
      };
    }),

    /**
     * @property itemComponent
     * @type {String}
     * @private
     */
    itemComponent: 'bs-dropdown/menu/item',

    /**
     * @property linkToComponent
     * @type {String}
     * @private
     */
    linkToComponent: 'bs-dropdown/menu/link-to',

    /**
     * @property dividerComponent
     * @type {String}
     * @private
     */
    dividerComponent: 'bs-dropdown/menu/divider'
  });

  _exports.default = _default;
});