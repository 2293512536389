define("ember-bootstrap/components/bs-nav/item", ["exports", "ember-bootstrap/components/base/bs-nav/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _item.default.extend({
    classNames: ['nav-item'],
    linkClass: 'nav-link'
  });

  _exports.default = _default;
});